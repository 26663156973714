body {
  background-color: #000000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  position: relative;
  min-height: 100vh;
}

.App {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  background-color: #24013d;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  color: white;
  margin: 0px;
}
.App-header p {
  padding-top: 20px;
}

.App-links {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  -webkit-column-count: 5;
          column-count: 5;
  grid-column-gap: 100px;
  -webkit-column-gap: 100px;
          column-gap: 100px;
}

.App-links a {
  color: #61dafb;
}
